import React, { useCallback, useEffect, useState } from "react";
import CustomLayout from "../../Components/CustomLayout/CustomLayout";
import useTableHeight from "../../Services/TableHeight";
import "./People.scss";
import { Table } from "antd";
import { Resizable } from "react-resizable";
import { tableData2 } from "../../Services/tableData";
import { postApi } from "../../Utils/apiService";
import { GET_ALL_WEBHOOKEVENTS } from "../../Utils/apiPath";
import { useUserContext } from "../../Services/UserContext";
import { formatTimeDifference, ResizableTitle } from "../../Services/HelperFunctions";

const People = () => {
  const { profileData, isLoading, setIsLoading } = useUserContext();

  const [columns, setColumns] = useState([
    // {
    //   title: "S.No",
    //   key: "index",
    //   width: 70,
    //   render: (text, record, index) => `${(pageIndex - 1) * pageSize + index + 1}`,
    // },
    {
      title: "Identity",
      dataIndex: "identity",
      key: "identity",
      width: 150,
      sorter: (a, b) => (a.identity || "New User").localeCompare(b.identity || "New User"),
      render: (identity) => identity || "New User",
    },
    {
      title: "Company Industry",
      dataIndex: "industry",
      key: "industry",
      width: 200,
      sorter: (a, b) => (a.industry || "--").localeCompare(b.industry || "--"),
    },
    {
      title: "Company Employee Range",
      dataIndex: "employeeRange",
      key: "employeeRange",
      width: 200,
      sorter: (a, b) => (a.employeeRange || "--").localeCompare(b.employeeRange || "--"),
    },
    {
      title: "User Total Active Time",
      dataIndex: "totalActiveTime",
      key: "totalActiveTime",
      width: 200,
      sorter: (a, b) => (a.totalActiveTime || "--").localeCompare(b.totalActiveTime || "--"),
    },
    {
      title: "Last Activity",
      dataIndex: "lastActivity",
      key: "lastActivity",
      width: 150,
      sorter: (a, b) => (a.lastActivity || 0) - (b.lastActivity || 0), // Sorting by timestamp (number)
      render: (timestamp) => {
        return timestamp && !isNaN(Number(timestamp))
          ? formatTimeDifference(Number(timestamp))
          : "--";
      },
    },
  ]);

  const [tableData, setTableData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const tableHeight = useTableHeight();

  const handleResize =
    (index) =>
      (e, { size }) => {
        const nextColumns = [...columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        setColumns(nextColumns);
      };

  const mergedColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
    ellipsis: true,
  }));

  const getPageSizeOptions = () => {
    if (totalCount <= 50) {
      return ["10", "20", "50"];
    } else {
      return ["10", "20", "50", "100"];
    }
  };


  const fetchPeopleData = useCallback(async () => {
    const { statusCode, data } = await postApi(
      GET_ALL_WEBHOOKEVENTS,
      {},
      { params: { ProjectName: profileData.ProjectName } }
    );
  
    if (statusCode === 200 && data) {
      const { messageProperties } = data;
  
      const columnMapping = {
        identity: "User Identity",
        industry: "Company Industry",
        employeeRange: "Company Employee Range",
        totalActiveTime: "User Total Active Time",
        lastActivity: "Timestamp",
      };
  
      // Accumulate rows data
      const rows = messageProperties.reduce((acc, prop) => {
        const propValues = prop.propValues || [];
  
        propValues.forEach((value, i) => {
          if (!acc[i]) acc[i] = { allData: {} };  // Initialize row if not exists
  
          // Store the full propValues in allData
          acc[i].allData[prop.displayName] = value;
  
          // Map prop.displayName to the corresponding column key
          const columnKey = Object.keys(columnMapping).find(
            (key) => columnMapping[key] === prop.displayName
          );
  
          // Assign value to the correct column
          if (columnKey) {
            acc[i][columnKey] = columnKey === "identity" ? value || "New User" : value || "--";
          }
        });
  
        return acc;
      }, []);
  
      // Ensure that every row has default values for all columns in columnMapping
      const completeRows = rows.map((row) => {
        const completeRow = { ...row };
        Object.keys(columnMapping).forEach((key) => {
          if (!completeRow[key]) {
            completeRow[key] = key === "identity" ? "New User" : "--";
          }
        });
        return completeRow;
      });
  
      setTableData(completeRows);
      setTotalCount(completeRows.length);
    }
  }, [profileData]);
  
  

  useEffect(() => {
    if (profileData?.ProjectName) {
      fetchPeopleData();
    }
  }, [profileData]);

  const paginatedData = tableData.slice((pageIndex - 1) * pageSize, pageIndex * pageSize);


  const handleRowClick = (record) => {
    console.log("Complete data of clicked row:", record.allData);  // Log full row data
    setSelectedRowData(record.allData);  // Store the full row data in the state
  };

  return (
    <>
      <div className="acc">
        <div className="acc_top">
          <h2>All People</h2>
        </div>
        <div className="acc_section">
          <div className="acc_section_table">
            <Table
              bordered
              columns={mergedColumns}
              dataSource={paginatedData}
              components={{
                header: {
                  cell: ResizableTitle,
                },
              }}
              pagination={{
                current: pageIndex,
                pageSize,
                total: totalCount,
                showSizeChanger: true,
                pageSizeOptions: getPageSizeOptions(),
                onChange: (page, size) => {
                  setPageIndex(page);
                  setPageSize(size);
                },
              }}
              scroll={{
                x: 1500,
                y: tableHeight,
              }}
              rowKey="id"
              onRow={(record) => ({
                onClick: () => handleRowClick(record),  // Handle row click
              })}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default People;
