import React from "react";

const WindowIcon = ({className}) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 48 48"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.5 8H10.5C7.96875 8 6 10.0391 6 12.5V35C6 37.5312 7.96875 39.5 10.5 39.5H37.5C39.961 39.5 42 37.5312 42 35V12.5C42 10.0391 39.961 8 37.5 8ZM26.25 12.5C27.4453 12.5 28.5 13.5547 28.5 14.75C28.5 16.0156 27.4453 17 26.25 17C24.9844 17 24 16.0156 24 14.75C24 13.5547 24.9844 12.5 26.25 12.5ZM19.5 12.5C20.6953 12.5 21.75 13.5547 21.75 14.75C21.75 16.0156 20.6953 17 19.5 17C18.2344 17 17.25 16.0156 17.25 14.75C17.25 13.5547 18.2344 12.5 19.5 12.5ZM12.75 12.5C13.9453 12.5 15 13.5547 15 14.75C15 16.0156 13.9453 17 12.75 17C11.4844 17 10.5 16.0156 10.5 14.75C10.5 13.5547 11.4844 12.5 12.75 12.5ZM38.625 35C38.625 35.6328 38.0625 36.125 37.5 36.125H10.5C9.86719 36.125 9.375 35.6328 9.375 35V21.5H38.625V35Z"
        fill="#7e04d5"
      />
    </svg>
  );
};

export default WindowIcon;
