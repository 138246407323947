import React from "react";

const UpTrend = ({color = "#000"}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.70488 5.60777L14.25 4L12.8698 9.6061L11.148 7.9532L8.37069 10.8463C8.27641 10.9445 8.14615 11 8.01 11C7.87385 11 7.74359 10.9445 7.64931 10.8463L5.61 8.72199L2.61069 11.8463C2.41946 12.0455 2.10294 12.0519 1.90373 11.8607C1.70453 11.6695 1.69807 11.3529 1.88931 11.1537L5.24931 7.65373C5.34359 7.55552 5.47385 7.5 5.61 7.5C5.74615 7.5 5.87641 7.55552 5.97069 7.65373L8.01 9.77801L10.4266 7.26067L8.70488 5.60777Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};

export default UpTrend;
