export const industry_data = [
  "Accounting",
  "Advertising",
  "Aerospace and Defense",
  "Agriculture",
  "Air Freight & Logistics",
  "Apparel",
  "Associations",
  "Asset Management & Custody Banks",
  "Automation",
  "Automotive",
  "Banks",
  "Beverages",
  "Biotech",
  "Building Materials",
  "Business Services",
  "Capital Markets",
  "Chemicals",
  "Commercial Services & Supplies",
  "Communications Equipment",
  "Construction",
  "Construction & Engineering",
  "Consulting",
  "Consumer Discretionary",
  "Consumer Goods",
  "Consumer Goods and Services",
  "Consumer Services",
  "Consumer Staples",
  "Containers & Packaging",
  "Distributors",
  "Diversified Consumer Services",
  "Diversified Financial Services",
  "Diversified Telecommunication Services",
  "Education",
  "Education Services",
  "Electrical Equipment",
  "Electronic Equipment, Instruments & Components",
  "Energy and Utilities",
  "Family Services",
  "Financial Services",
  "Food & Staples Retailing",
  "Food and Beverage",
  "Food Products",
  "Gas Utilities",
  "Government",
  "Hardware",
  "Health Care Equipment & Supplies",
  "Health Care Providers & Services",
  "Healthcare and Medical",
  "Home and Garden",
  "Hospitality and Travel",
  "Household Durables",
  "Industrial Conglomerates",
  "Insurance",
  "Internet Software & Services",
  "IT Services",
  "Leisure Products",
  "Life Sciences Tools & Services",
  "Machinery",
  "Manufacturing",
  "Marine",
  "Media and Entertainment",
  "Metals & Mining",
  "Paper & Forest Products",
  "Personal Products",
  "Pharmaceuticals",
  "Printing and Publishing",
  "Professional Services",
  "Real Estate",
  "Recreation",
  "Renewable Electricity",
  "Retail and Distribution",
  "Retailing",
  "Road & Rail",
  "Semiconductors & Semiconductor Equipment",
  "Software and Technology",
  "Specialized Consumer Services",
  "Specialty Retail",
  "Telecommunications",
  "Textiles",
  "Textiles, Apparel & Luxury Goods",
  "Transportation and Logistics",
  "Utilities",
  "Wireless Telecommunication Services",
].map((name, index) => ({ name, checked: false, id: index + 1 }));

export const department_data = [
  { name: "Finance" },
  { name: "Medical" },
  { name: "Education" },
  { name: "Engineer" },
  { name: "Sales" },
  { name: "Others" },
  { name: "Marketing" },
  { name: "Sub Level  Management" },
  { name: "HR" },
  { name: "IT" },
  { name: "Information Technology" },
  { name: "Operation" },
  { name: "Top Level  Management" },
  { name: "Account" },
  { name: "Customer  Service" },
  { name: "Arts&Entertainment" },
  { name: "B&D" },
  { name: "Legal" },
  { name: "R&D" },
  { name: "Consultants" },
  { name: "Purchasing" },
  { name: "Defense" },
  { name: "Architect" },
  { name: "Manufacturing" },
  { name: "Quality" },
  { name: "Partner" },
  { name: "Government" },
  { name: "Sales&Marketing" },
  { name: "Board  Member" },
  { name: "Top  Management" },
].map((item, index) => ({ ...item, checked: false, id: index + 1 }));

export const level_data = [
  { name: "C-Level" },
  { name: "VP-Level" },
  { name: "Director" },
  { name: "Managers" },
  { name: "Non-Managers" },
].map((item, index) => ({ ...item, checked: false, id: index + 1 }));

export const employee_data = [
  { name: "1-10" },
  { name: "11-50" },
  { name: "51-250" },
  { name: "251-1K" },
  { name: "1K-5K" },
  { name: "10K-50K" },
  { name: "50K-100K" },
  { name: "100K+" },
].map((item, index) => ({ ...item, checked: false, id: index + 1 }));

export const revenue_data = [
  { name: "$0-$1M" },
  { name: "$1M-$10M" },
  { name: "$10M-$50M" },
  { name: "$50M-$100M" },
  { name: "$100M-$250M" },
  { name: "$250M-$500M" },
  { name: "$500M-$1B" },
  { name: "$1B-$10B" },
  { name: "$10B+" },
].map((item, index) => ({ ...item, checked: false, id: index + 1 }));

export const location_data = [
  { name: "United States" },
  { name: "United Kingdom" },
  { name: "India" },
  { name: "Afghanistan" },
  { name: "Algeria" },
  { name: "Argentina" },
  { name: "Australia" },
  { name: "Austria" },
  { name: "Azerbaijan" },
  { name: "Bahrain" },
  { name: "Bangladesh" },
  { name: "Belarus" },
  { name: "Belgium" },
  { name: "Bolivia" },
  { name: "Bosnia and Herzegovina" },
  { name: "Brazil" },
  { name: "Bulgaria" },
  { name: "Cambodia" },
  { name: "Canada" },
  { name: "Chile" },
  { name: "China" },
  { name: "Colombia" },
  { name: "Costa Rica" },
  { name: "Croatia" },
  { name: "Cyprus" },
  { name: "Czech Republic" },
  { name: "Denmark" },
  { name: "Dominican Republic" },
  { name: "Ecuador" },
  { name: "Egypt" },
  { name: "Estonia" },
  { name: "Finland" },
  { name: "France" },
  { name: "Georgia" },
  { name: "Germany" },
  { name: "Ghana" },
  { name: "Greece" },
  { name: "Guatemala" },
  { name: "Hong Kong" },
  { name: "Hungary" },
  { name: "Iceland" },
  { name: "Indonesia" },
  { name: "Iran" },
  { name: "Ireland" },
  { name: "Israel" },
  { name: "Italy" },
  { name: "Japan" },
  { name: "Jordan" },
  { name: "Kazakhstan" },
  { name: "Kenya" },
  { name: "Korea" },
  { name: "Kuwait" },
  { name: "Latvia" },
  { name: "Lebanon" },
  { name: "Lithuania" },
  { name: "Luxembourg" },
  { name: "Macedonia" },
  { name: "Malaysia" },
  { name: "Malta" },
  { name: "Mexico" },
  { name: "Morocco" },
  { name: "Nepal" },
  { name: "Netherlands" },
  { name: "New Zealand" },
  { name: "Nigeria" },
  { name: "Norway" },
  { name: "Pakistan" },
  { name: "Panama" },
  { name: "Peru" },
  { name: "Philippines" },
  { name: "Poland" },
  { name: "Portugal" },
  { name: "Puerto Rico" },
  { name: "Qatar" },
  { name: "Romania" },
  { name: "Russian Federation" },
  { name: "Saudi Arabia" },
  { name: "Serbia" },
  { name: "Singapore" },
  { name: "Slovak Republic" },
  { name: "Slovenia" },
  { name: "South Africa" },
  { name: "Spain" },
  { name: "Sri Lanka" },
  { name: "Sweden" },
  { name: "Switzerland" },
  { name: "Taiwan" },
  { name: "Tanzania" },
  { name: "Thailand" },
  { name: "Tunisia" },
  { name: "Turkey" },
  { name: "Uganda" },
  { name: "Ukraine" },
  { name: "United Arab Emirates" },
  { name: "Uruguay" },
  { name: "Venezuela" },
  { name: "Vietnam" },
].map((item, index) => ({ ...item, checked: false, id: index + 1 }));
