import React, { useCallback, useEffect, useState } from "react";
import "./Dashboard.scss";
import Card from "../../Components/Card/Card";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import mapModule from "highcharts/modules/map";
import MapChart from "../../Components/MapChart/MapChart";
import DownArrow from "../../Assets/Icons/DownArrow";
import Accessibility from "highcharts/modules/accessibility";
import { Button, Dropdown, Space, DatePicker } from "antd";
import { postApi } from "../../Utils/apiService";
import {
  GET_ALL_CAMPAIGNS,
  GET_BROWSER_STATS,
  GET_CAMPAIGN_STATS,
  GET_DEVICE_STATS,
  GET_DEVICE_STATS_MONTHLY,
} from "../../Utils/apiPath";
import dayjs from "dayjs";
import Loader from "../../Components/Loader/Loader";

const { RangePicker } = DatePicker;
Accessibility(Highcharts);
mapModule(Highcharts);

const Dashboard = () => {
  const [stats, setStats] = useState([]);
  const [browserStats, setBrowserStats] = useState([]);
  const [deviceStats, setDeviceStats] = useState({
    categories: [],
    openData: [],
    clickData: [],
  });
  const [monthlyStats, setMonthlyStats] = useState({
    categories: [],
    openData: [],
    clickData: [],
  });
  const [loading, setLoading] = useState(true);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [dateRange, setDateRange] = useState(null);

  const handleDateChange = (dates, dateStrings) => {
    if (dates) {
      setDateRange(dateStrings);
    } else {
      setDateRange([]);
    }
  };

  const handleMenuClick = (e) => {
    const selected = campaigns.find(
      (campaign) => campaign.campaignId === e.key
    );
    setSelectedCampaign(selected || null);
  };

  const fetchAllCampaigns = useCallback(async () => {
    setLoading(true);
    try {
      const { statusCode, data } = await postApi(GET_ALL_CAMPAIGNS);
      if (statusCode === 200) {
        setCampaigns(data);
        setSelectedCampaign(data[0] || null);
      } else {
        setCampaigns([]);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchStats = useCallback(async () => {
    if (!selectedCampaign) return;
    setLoading(true);
    const payload = {
      campaignId: selectedCampaign?.campaignId,
      apiKey: selectedCampaign?.apiKey,
      userApiEndPoint: selectedCampaign?.userApiEndPoint,
    };
    try {
      const { statusCode, data } = await postApi(GET_CAMPAIGN_STATS, payload);
      if (statusCode === 200) {
        setStats(data.data);
      }
    } finally {
      setLoading(false);
    }
  }, [selectedCampaign]);

  const fetchBrowserStats = useCallback(async () => {
    if (!selectedCampaign) return;
    setLoading(true);
    const payload = {
      campaignId: selectedCampaign.id,
      fromDate: dateRange?.length === 2 ? dateRange[0] : null,
      toDate: dateRange?.length === 2 ? dateRange[1] : null,
    };
    const { statusCode, data } = await postApi(GET_BROWSER_STATS, payload);
    if (statusCode === 200) {
      setBrowserStats(data);
      setLoading(false);
    } else {
      setBrowserStats([]);
      setLoading(false);
    }
  }, [selectedCampaign, dateRange]);

  const fetchDeviceStats = useCallback(async () => {
    if (!selectedCampaign) return;
    setLoading(true);

    const payload = {
      campaignId: selectedCampaign.id,
      fromDate: dateRange?.length === 2 ? dateRange[0] : null,
      toDate: dateRange?.length === 2 ? dateRange[1] : null,
    };

    const { statusCode, data } = await postApi(GET_DEVICE_STATS, payload);
    if (statusCode === 200) {
      const deviceNames = {
        1: "Desktop",
        2: "Tablet",
        3: "Mobile",
        4: "WebMail",
        5: "Others",
      };
      const categories = [];
      const openData = [];
      const clickData = [];

      data.forEach((device) => {
        const deviceName = deviceNames[device.deviceId] || "Unknown";
        categories.push(deviceName);
        openData.push(device.openCount);
        clickData.push(device.clickCount);
      });

      setDeviceStats({ categories, openData, clickData });
    } else {
      setDeviceStats({
        openData: [],
        clickData: [],
      });
    }
  }, [selectedCampaign, dateRange]);

  const fetchMonthlyStats = useCallback(async () => {
    if (!selectedCampaign) return;
    setLoading(true);
    const payload = {
      campaignId: selectedCampaign.id,
      fromDate: dateRange?.length === 2 ? dateRange[0] : null,
      toDate: dateRange?.length === 2 ? dateRange[1] : null,
    };
    const { statusCode, data } = await postApi(
      GET_DEVICE_STATS_MONTHLY,
      payload
    );
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    if (statusCode === 200 && data?.length) {
      const openData = new Array(12).fill(0);
      const clickData = new Array(12).fill(0);

      data.forEach((item) => {
        const monthIndex = item.month - 1;
        openData[monthIndex] = item.totalOpenCount;
        clickData[monthIndex] = item.totalClickCount;
      });

      setMonthlyStats({ categories: monthNames, openData, clickData });
    } else {
      const openData = new Array(12).fill(0);
      const clickData = new Array(12).fill(0);
      setMonthlyStats({ categories: monthNames, openData, clickData });
    }
    setLoading(false);
  }, [selectedCampaign, dateRange]);

  useEffect(() => {
    fetchAllCampaigns();
  }, [fetchAllCampaigns]);

  useEffect(() => {
    fetchStats();
  }, [fetchStats]);

  useEffect(() => {
    fetchBrowserStats();
    fetchDeviceStats();
    fetchMonthlyStats();
  }, [dateRange, fetchBrowserStats, fetchDeviceStats, fetchMonthlyStats]);

  useEffect(() => {
    if (dateRange?.length === 2) {
      fetchBrowserStats();
      fetchDeviceStats();
      fetchMonthlyStats();
    }
  }, [dateRange, fetchBrowserStats, fetchDeviceStats, fetchMonthlyStats]);

  const menuItems = campaigns.map((campaign) => ({
    key: campaign.campaignId,
    label: campaign.campaignName,
  }));

  const menu = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  const cards = [
    {
      bg: "#F8EFFF",
      heading: "ICP Targeted",
      value: stats?.subscribersCount?.toLocaleString(),
      percentage: stats?.deliverySuccessRate?.toFixed(2),
    },
    {
      bg: "#FFF7E4",
      heading: "Unique Visits",
      value: stats?.uniqueOpensCount?.toLocaleString(),
      percentage: stats?.uniqueOpensRate?.toFixed(2),
    },
    {
      bg: "#E3F5FF",
      heading: "Unique ICP Responses",
      value: stats?.uniqueClicksCount?.toLocaleString(),
      percentage: stats?.uniqueClicksRate?.toFixed(2),
    },
    {
      bg: "#FFE8EB",
      heading: "ICP Reached",
      value: stats?.deliverySuccessCount?.toLocaleString(),
      percentage: stats?.deliverySuccessRate?.toFixed(2),
    },
    {
      bg: "#E6EEFF",
      heading: "Opt-Outs",
      value: stats?.unsubscribesCount?.toLocaleString(),
      percentage: stats?.unsubscribesRate?.toFixed(2),
    },
    // {
    //   bg: "#FFE7F0",
    //   heading: "Complaints",
    //   value: stats.complaints_count,
    //   percentage: stats.complaints_rate?.toFixed(2),
    // },
  ];

  const options = {
    chart: {
      className: "dashboard-chart",
      type: "pie",
      height: 200,
      backgroundColor: "#F7F9FB",
    },
    title: {
      text: "ICP % by Browser",
      align: "left",
      style: {
        fontSize: "14px",
      },
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    tooltip: {
      pointFormat: "<b>{point.y}%</b>",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        innerSize: "60%",
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
    legend: {
      align: "right",
      verticalAlign: "bottom",
      layout: "vertical",
      itemMarginBottom: 8,
      symbolRadius: 50,
      labelFormat: "{name}: {y}%",
      itemStyle: {
        fontSize: "10px",
        fontWeight: "normal",
      },
    },
    series: [
      {
        name: "Clicks",
        colorByPoint: true,
        data: [
          {
            name: "Chrome",
            y: browserStats.chromePercentage,
            color: "#8A2BE2",
          },
          {
            name: "Mozilla",
            y: browserStats.mozillaPercentage,
            color: "#4169E1",
          },
          {
            name: "Safari",
            y: browserStats.safariPercentage,
            color: "#FF69B4",
          },
          { name: "Edge", y: browserStats.edgePercentage, color: "#EE82EE" },
          { name: "Opera", y: browserStats.operaPercentage, color: "#FFA500" },
        ],
      },
    ],
  };

  const options2 = {
    chart: {
      type: "column",
      height: 260,
      backgroundColor: "#F7F9FB",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: deviceStats.categories,
      title: {
        text: null,
      },
    },
    yAxis: {
      min: 0,
      max: 30000,
      tickInterval: 10000,
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          return this.value / 1000 + "K";
        },
      },
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: false,
        },
        borderRadius: 5,
        pointPadding: 0.1,
        groupPadding: 0.2,
        borderWidth: 0,
      },
    },
    legend: {
      layout: "horizontal",
      align: "right",
      verticalAlign: "top",
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    series: [
      {
        name: "Interest",
        color: "#830085",
        data: deviceStats.openData,
      },
      {
        name: "Visits",
        color: "#FA4300",
        data: deviceStats.clickData,
      },
    ],
  };

  const options3 = {
    chart: {
      type: "areaspline",
      height: 270,
      backgroundColor: "#F7F9FB",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: monthlyStats.categories,
      title: {
        text: null,
      },
      labels: {
        style: {
          fontSize: "12px",
          color: "#000",
        },
      },
      lineColor: "transparent",
    },
    yAxis: {
      min: 0,
      max: 40000,
      tickInterval: 10000,
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          return this.value / 1000 + "K";
        },
        style: {
          fontSize: "12px",
          color: "#000",
        },
      },
      gridLineColor: "transparent",
    },
    legend: {
      layout: "horizontal",
      align: "left",
      verticalAlign: "top",
      itemStyle: {
        color: "#000",
        fontSize: "12px",
      },
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      valueSuffix: "",
      formatter: function () {
        let tooltip = `<b>${this.x}</b><br/>`;
        this.points.forEach((point) => {
          tooltip += `<b>${
            point.series.name
          }</b>: ${point.y.toLocaleString()}<br/>`;
        });
        return tooltip;
      },
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.4,
        marker: {
          enabled: false,
        },
        lineWidth: 1.5,
        states: {
          hover: {
            enabled: true,
            lineWidthPlus: 0,
          },
        },
        smooth: true,
      },
    },
    series: [
      {
        name: "Interest",
        color: {
          linearGradient: [0, 0, 0, 300],
          stops: [
            [0, "rgba(126, 4, 213, 0.4)"],
            [1, "rgba(247, 249, 251, 1)"],
          ],
        },
        data: monthlyStats.openData,
      },
      {
        name: "Visits",
        dashStyle: "ShortDash",
        color: {
          linearGradient: [0, 0, 0, 300],
          stops: [
            [0, "rgba(255, 50, 161, 0.4)"],
            [1, "rgba(247, 249, 251, 1)"],
          ],
        },
        data: monthlyStats.clickData,
      },
    ],
  };

  return (
    <>
      <div className="dashboard">
      {loading && <Loader />}
        <div className="dashboard_container">
          <div className="dashboard_heading">
            <div className="dashboard_heading_left">
              <h1>Dashboard</h1>
            </div>
            <div className="dashboard_heading_right">
              <Space
                direction="vertical"
                size={12}
                style={{ marginRight: "10px" }}
              >
                <RangePicker
                  value={
                    dateRange
                      ? [
                          dayjs(dateRange[0], "YYYY-MM-DD"),
                          dayjs(dateRange[1], "YYYY-MM-DD"),
                        ]
                      : null
                  }
                  onChange={handleDateChange}
                />
              </Space>
              <Dropdown menu={menu} trigger={["click"]} size="small">
                <Button>
                  {selectedCampaign
                    ? selectedCampaign?.campaignName
                    : "Select Campaign"}
                  <DownArrow />
                </Button>
              </Dropdown>
            </div>
          </div>
          <div className="dashboard_sections">
            <div className="dashboard_sections_top">
              <div className="dashboard_sections_top_cards">
                {cards?.map((card, index) => {
                  return <Card card={card} key={index} />;
                })}
              </div>
              <div className="dashboard_sections_top_chart">
                <HighchartsReact highcharts={Highcharts} options={options} />
              </div>
            </div>
            <div className="dashboard_sections_center">
              <div className="dashboard_sections_center_left">
                <MapChart />
              </div>
              <div className="dashboard_sections_center_right">
                <HighchartsReact highcharts={Highcharts} options={options2} />
              </div>
            </div>
            <div className="dashboard_sections_bottom">
              <HighchartsReact highcharts={Highcharts} options={options3} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
