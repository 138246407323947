import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { useLocation, useNavigate } from "react-router-dom"; // assuming you're using react-router
import "./ResetPassword.scss";
import Logo from "../../Assets/Images/Login-Logo.png";
import { postApi } from "../../Utils/apiService";
import { RESET_PASSWORD } from "../../Utils/apiPath";
import { errorToast, successToast } from "../../Services/ToastHelper";
import Loader from "../../Components/Loader/Loader";
import Buttons from "../../Components/Buttons/Buttons";
import FormInputs from "../../Components/FormInputs/FormInputs";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  // Extract email from URL query parameters
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const emailFromUrl = searchParams.get("email");

  // useEffect(() => {
  //     const searchParams = new URLSearchParams(location.search);
  //     const emailParam = searchParams.get("email");
  //     if (emailParam) {
  //         setEmail(emailParam);
  //     } else {
  //         setError("Invalid or missing email");
  //     }
  // }, [location]);

  console.log("emailFromUrl", emailFromUrl);

  const handleResetPassword = async () => {
    if (validatePasswords()) {
      setIsLoading(true);
      const payload = { email: emailFromUrl, password: newPassword };
      const { statusCode, message, error } = await postApi(
        RESET_PASSWORD,
        payload
      );
      if (statusCode === 200) {
        successToast(message);
        navigate("/");
        setIsLoading(false);
      } else {
        errorToast(error);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };

  // Validate passwords and check them on change as well
  const validatePasswords = () => {
    if (!newPassword || !confirmPassword) {
      setError("All fields are required");
      return false;
    } else if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return false;
    } else if (newPassword.length < 8) {
      setError("Password must be at least 8 characters");
      return false;
    }
    setError("");
    return true;
  };

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
    if (confirmPassword && e.target.value !== confirmPassword) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (newPassword && e.target.value !== newPassword) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };

  return (
    <div className="reset-password">
      {isLoading && <Loader />}
      <div className="reset-password_cont">
        <div className="reset-password_cont_sec">
          <div className="reset-password_cont_sec_left">
            <div className="reset-password_cont_sec_left_logo">
              <img src={Logo} alt="Company Logo" />
            </div>
            <div className="reset-password_cont_sec_left_content">
              <div className="reset-password_cont_sec_left_content_head">
                <h1>Reset Your Password</h1>
                <p>Enter your new password and confirm it below</p>
              </div>
              <div className="reset-password_cont_sec_left_content_data">
                <div className="reset-password_cont_sec_left_content_data_input">
                  <FormInputs
                    title={"Password"}
                    type={"password"}
                    placeholder="New Password"
                    value={newPassword}
                    onChange={handlePasswordChange}
                  />
                </div>
                <div className="reset-password_cont_sec_left_content_data_input">
                  <FormInputs
                    title={"Confirm Password"}
                    type={"password"}
                    placeholder="Confirm New Password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                  {error && <span className="error">{error}</span>}
                </div>
              </div>
              <div className="reset-password_cont_sec_left_content_button">
                <Buttons variant="primary" onClick={handleResetPassword}>
                  Reset Password
                </Buttons>
              </div>
              <div className="reset-password_cont_sec_left_content_copy">
                <p>© 2024 ALL RIGHTS RESERVED - LakeB2B</p>
              </div>
            </div>
          </div>
          <div className="reset-password_cont_sec_right"></div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
