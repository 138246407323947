import React from "react";
import "./Card.scss";
import UpTrend from "../../Assets/Icons/UpTrend";
import DownTrend from "../../Assets/Icons/DownTrend";

const Card = ({ card }) => {
  return (
    <div
      className="dashboard-card"
      style={{ background: card.bg }}
    >
      <div className="dashboard-card_container">
        <div className="dashboard-card_heading">
          <h4>{card?.heading}</h4>
        </div>
        <div className="dashboard-card_section">
          <div className="dashboard-card_section_left">
            <h3>{card?.value}</h3>
          </div>
          <div className="dashboard-card_section_right">
          <h6>{card?.percentage}%</h6>
          {card?.percentage > 0 ? <UpTrend /> : <DownTrend />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
