import React from "react";

const PopupData = ({ urls }) => {
  return (
    <div className="modal-birdview">
      <h2>Page View</h2>
      <p>
        <strong>Page URL:</strong> {urls.pageUrl || "-"}
      </p>
      <p>
        <strong>Page Referrer URL:</strong> {urls.pageReferrerUrl || "-"}
      </p>
      <p>
        <strong>Page Scroll Percent:</strong> {urls.pageScrollPercent || "-"}
      </p>
      <p>
        <strong>Page Active Time:</strong> {urls.pageActiveTime || "-"}
      </p>
      <p>
        <strong>First Seen Day:</strong> {urls.firstSeenDay || "-"}
      </p>
      <p>
        <strong>User Latest Page URL:</strong> {urls.userLatestPageURL || "-"}
      </p>
    </div>
  );
};

export default PopupData;
