import React, { useEffect, useState } from "react";
import CustomLayout from "../../Components/CustomLayout/CustomLayout";
import "./Profile.scss";
import FormInputs from "../../Components/FormInputs/FormInputs";
import User from "../../Assets/Images/headeruser.png";
import { getApi, postApi } from "../../Utils/apiService";
import {
  GET_USER_PROFILE_DETAILS,
  PASSWORD_CHANGE_API,
  UPDATE_USER_PROFILE,
} from "../../Utils/apiPath";
import Buttons from "../../Components/Buttons/Buttons";
import { errorToast, successToast } from "../../Services/ToastHelper";
import CustomModal from "../../Components/CustomModal/CustomModal";
import Loader from "../../Components/Loader/Loader";

const Profile = () => {
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    projectId: "",
  });
  const [isEditable, setIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [resetModal, setResetModal] = useState(false);
  const [reset, setReset] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [errors, setErrors] = useState({});

  const handleResetClick = () => {
    setResetModal(true);
  };

  const handleCloseModal = () => {
    setResetModal(false);
    setReset({
      oldPassword: "",
      newPassword: "",
    });
  };

  const getUserProfileData = async () => {
    setIsLoading(true);
    const { statusCode, data } = await getApi(GET_USER_PROFILE_DETAILS);
    if (statusCode === 200) {
      setIsLoading(false);
      setProfileData(data);
    } else {
      setIsLoading(false);
      setProfileData({});
    }
  };

  useEffect(() => {
    getUserProfileData();
  }, []);

  const handleEditClick = () => {
    setIsEditable(!isEditable);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfileData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveClick = async () => {
    setIsLoading(true);
    const { statusCode, message, error } = await postApi(
      UPDATE_USER_PROFILE,
      profileData
    );
    if (statusCode === 200) {
      setIsEditable(false);
      setIsLoading(false);
      successToast(message);
    } else {
      errorToast(error);
      setIsLoading(false);
    }
  };

  const handlePasswordInputChange = (event) => {
    const { name, value } = event.target;
    setReset((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
      general: "",
      samePassword:
        name === "newPassword" && reset.oldPassword === value
          ? "Old and new password cannot be the same"
          : "",
    }));
  };

  const validatePasswords = (oldPassword, newPassword) => {
    let errors = {};
    if (!oldPassword || !newPassword) {
      errors.general = "Both fields are required";
    } else if (oldPassword === newPassword) {
      errors.samePassword = "Old and new password cannot be the same";
    } else if (newPassword.length < 8) {
      errors.newPassword = "New password must be at least 8 characters";
    } else {
      errors.newPassword = "";
    }

    setErrors((prev) => ({ ...prev, ...errors }));
    return Object.values(errors).every((x) => x === "" || x === null);
  };

  const handleChangePassword = async () => {
    setIsLoading(true);
    if (validatePasswords(reset.oldPassword, reset.newPassword)) {
      const payload = {
        oldPassword: reset.oldPassword,
        password: reset.newPassword,
      };
      const { statusCode, message, error } = await postApi(
        PASSWORD_CHANGE_API,
        payload
      );
      if (statusCode === 200) {
        successToast(message);
        setResetModal(false);
        setIsLoading(false);
      } else {
        errorToast(error);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="profile">
        <div className="profile_cont">
          <div className="profile_cont_header">
            <h2>Profile Setting</h2>
          </div>
          <div className="profile_cont_sec">
            <div className="profile_cont_sec_header">
              <div className="profile_cont_sec_header_left">
                {/* <div className="profile_cont_sec_header_left_photo">
                  <img src={User} alt="user profile" />
                </div> */}
                <div className="profile_cont_sec_header_left_name">
                  <h3>{profileData.name}</h3>
                </div>
              </div>
              <div className="profile_cont_sec_header_right">
                <Buttons variant="small" onClick={handleEditClick}>
                  {isEditable ? "Cancel" : "Edit"}
                </Buttons>
                {isEditable && (
                  <Buttons variant="small" onClick={handleSaveClick}>
                    Save
                  </Buttons>
                )}
                {!isEditable && (
                  <Buttons variant="small" onClick={handleResetClick}>
                    Reset Password
                  </Buttons>
                )}
              </div>
            </div>
            <div className="profile_cont_sec_data">
              <div className="profile_cont_sec_data_left">
                <div className="profile_cont_sec_data_left_inputs">
                  <FormInputs
                    title="Name"
                    type={"text"}
                    placeholder={"Name"}
                    value={profileData.name}
                    name="name"
                    onChange={handleInputChange}
                    disabled={!isEditable}
                  />
                </div>
                <div className="profile_cont_sec_data_left_inputs">
                  <FormInputs
                    title="Phone Number"
                    type={"text"}
                    placeholder={"Phone Number"}
                    value={profileData.phoneNumber}
                    name="phoneNumber"
                    onChange={handleInputChange}
                    disabled={!isEditable}
                  />
                </div>
                <div className="profile_cont_sec_data_left_inputs">
                  <FormInputs
                    title="Company Name"
                    type={"text"}
                    placeholder={"Project Name"}
                    value={profileData.companyName}
                    name="ProjectName"
                    disabled={true}
                  />
                </div>
                <div className="profile_cont_sec_data_left_inputs">
                  <FormInputs
                    title="LinkedIn Segment"
                    type={"text"}
                    placeholder={"LinkedIn Segment"}
                    value={profileData.linkedInProjectName}
                    name="linkedInProjectName"
                    disabled={true}
                  />
                </div>
              </div>
              <div className="profile_cont_sec_data_right">
                <div className="profile_cont_sec_data_right_inputs">
                  <FormInputs
                    title="Email"
                    type={"text"}
                    placeholder={"Email"}
                    value={profileData.email}
                    name="email"
                    disabled={true}
                  />
                </div>
                <div className="profile_cont_sec_data_right_inputs">
                  <FormInputs
                    title="Project Number"
                    type={"text"}
                    placeholder={"Project Number"}
                    value={profileData.projectId}
                    name="projectId"
                    disabled={true}
                  />
                </div>
                <div className="profile_cont_sec_data_right_inputs">
                  <FormInputs
                    title="Project Name"
                    type={"text"}
                    placeholder={"Project Name"}
                    value={profileData.ProjectName}
                    name="projectName"
                    disabled={true}
                  />
                </div>
                <div className="profile_cont_sec_data_right_inputs">
                  <FormInputs
                    title="Google Ads Segment"
                    type={"text"}
                    placeholder={"Google Ads Segment"}
                    value={profileData.goggleAdProjectName}
                    name="goggleAdProjectName"
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="profile_cont_sec_last"></div>
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={resetModal}
        onClose={handleCloseModal}
        title={"Reset Password"}
        showPrimaryButton={true}
        showSecondaryButton={true}
        onPrimaryClick={handleChangePassword}
        onSecondaryClick={handleCloseModal}
        primaryLabel="Submit"
        secondaryLabel="Cancel"
      >
        <div className="form">
          <div className="form_inputs">
            <FormInputs
              type={"password"}
              placeholder={"Old Password"}
              value={reset.oldPassword}
              name="oldPassword"
              onChange={handlePasswordInputChange}
            />
          </div>
          <div className="form_inputs">
            <FormInputs
              type={"password"}
              placeholder={"New Password"}
              value={reset.newPassword}
              name="newPassword"
              onChange={handlePasswordInputChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleChangePassword();
                }
              }}
            />
            {errors.general && <span className="error">{errors.general}</span>}
            {errors.samePassword && (
              <span className="error">{errors.samePassword}</span>
            )}
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default Profile;
