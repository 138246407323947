import React from 'react';

const Analytics = () => {
  return (
    <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%"}}>
      <h2 style={{fontSize:"20px"}}>Comming Soon...</h2>
    </div>
  )
}

export default Analytics;
