import React, { useEffect, useState } from "react";
import { Layout, Menu, theme } from "antd";
import "./CustomLayout.scss";
import Logo from "../../Assets/Images/mainLogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "../../Assets/Icons/DashboardIcon";
import AccountsIcon from "../../Assets/Icons/AccountsIcon";
import AnalyticsIcon from "../../Assets/Icons/AnalyticsIcon";
import ProfileIcon from "../../Assets/Icons/ProfileIcon";
import LogoutIcon from "../../Assets/Icons/LogoutIcon";
import User from "../../Assets/Images/CampionImg.png";
import { SearchIcon } from "../../Assets/Icons/icons";
import ThemeIcon from "../../Assets/Icons/ThemeIcon";
import TimeIcon from "../../Assets/Icons/TimeIcon";
import NotificationIcon from "../../Assets/Icons/NotificationIcon";
import UsersIcon from "../../Assets/Icons/UsersIcon";
import { useUserContext } from "../../Services/UserContext";
import { CgWebsite } from "react-icons/cg";
import { ImProfile } from "react-icons/im";
import { LuLayoutDashboard } from "react-icons/lu";
import { FaGoogle } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { FaLinkedin } from "react-icons/fa";
import { TbLogout2 } from "react-icons/tb";
import { GrTemplate } from "react-icons/gr";
import { FiCpu } from "react-icons/fi";
const { Header, Content, Sider } = Layout;

const CustomLayout = ({ children }) => {
  const { profileData } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  const items = [
    {
      key: "1",
      icon: <LuLayoutDashboard />,
      label: "Dashboard",
      path: "/dashboard",
    },
    {
      key: "2",
      icon: <CgWebsite />,
      label: "Website Traffic",
      path: "/accounts",
    },
    {
      key: "3",
      icon: <FaGoogle />,
      label: "Google Ads",
      path: "/google-ads",
    },
    {
      key: "4",
      icon: <AiOutlineMail />,
      label: "Email Traffic",
      path: "/email-accs",
    },
    {
      key: "5",
      icon: <FaLinkedin />,
      label: "LinkedIn",
      path: "/linkedin-accs",
    },
    // {
    //   key: "3",
    //   icon: <UsersIcon />,
    //   label: "People",
    //   path: "/people",
    // },
    // {
    //   key: "4",
    //   icon: <AnalyticsIcon />,
    //   label: "Analytics",
    //   path: "/analytics",
    // },
    {
      key: "6",
      icon: <ImProfile />,
      label: "Profile",
      path: "/profile",
    },
    {
      key: "7",
      icon: <GrTemplate />,
      label: "Templates",
      path: null,
      externalLink: "https://viewmyprojects.com/showcasenew"
    },
    {
      key: "8",
      icon: <FiCpu />,
      label: "Set ICP",
      path: "/icp",
    },
    {
      key: "9",
      icon: <TbLogout2 />,
      label: "Logout",
      path: "/",
    },
  ];

  const [selectedKeys, setSelectedKeys] = useState([]);

  const handleMenuClick = (path, key, externalLink) => {
    if (externalLink) {
      window.open(externalLink, "_blank");
    } else {
      const currentPath = window.location.pathname;
      if (currentPath === path) {
        window.location.reload();
      } else {
        navigate(path);
      }
      setSelectedKeys([key]);
    }
  };

  useEffect(() => {
    const currentItem = items.find((item) => {
      if (item.path === location.pathname) return item;
      if (item.children) {
        return item.children.find(
          (subItem) => subItem.path === location.pathname
        );
      }
      return null;
    });

    if (currentItem) {
      const keys = currentItem.children
        ? [
            currentItem.key,
            currentItem.children.find(
              (subItem) => subItem.path === location.pathname
            ).key,
          ]
        : [currentItem.key];
      setSelectedKeys(keys);
    }
  }, [location.pathname]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout>
      <Header
        className="header"
        style={{
          padding: 0,
          background: colorBgContainer,
        }}
      >
        <div className="header_sec">
          <div className="header_sec_cont">
            <div
              className="header_sec_cont_left"
              onClick={() => handleMenuClick("/dashboard", "1")}
            >
              <div className="header_sec_cont_left_logo">
                <img src={Logo} alt="logo" />
              </div>
            </div>
            {/* <div className="header_sec_cont_right">
              <div className="header_sec_cont_right_search">
                <input type="text" placeholder="Search" />
                <SearchIcon className="searchicon" />
              </div>
              <div className="header_sec_cont_right_icons">
                <div className="theme">
                  <ThemeIcon />
                </div>
                <div className="time">
                  <TimeIcon />
                </div>
                <div className="notification">
                  <NotificationIcon />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </Header>
      <Layout>
        <Sider
          className="sidebar"
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {}}
          onCollapse={(collapsed, type) => {}}
        >
          <div
            className="sidebar_main"
            onClick={() => handleMenuClick("/profile", "5")}
          >
            <img src={User} alt="User Image" />
            <h3>{profileData.name}</h3>
          </div>
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={selectedKeys}
            items={items.map(
              ({ key, icon, label, path, externalLink, children }) => {
                if (children) {
                  return {
                    key,
                    icon,
                    label,
                    children: children.map((subItem) => ({
                      key: subItem.key,
                      icon: subItem.icon,
                      label: subItem.label,
                      onClick: () => handleMenuClick(subItem.path, subItem.key),
                    })),
                    className: "custom-menu-item",
                  };
                }
                return {
                  key,
                  icon,
                  label,
                  onClick: () => handleMenuClick(path, key, externalLink),
                };
              }
            )}
          />
        </Sider>
        <Content className="content">
          <div className="content_center">{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default CustomLayout;
