import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs, Table, Empty, Popover } from "antd";
import { useUserContext } from "../../Services/UserContext";
import {
  GET_ALL_BIRDVIEW,
  GET_ALL_TIMELINE,
  GET_BIRDVIEW_DATA,
} from "../../Utils/apiPath";
import { postApi } from "../../Utils/apiService";
import { Drawer } from "antd";
import "./GoogleAdsDetails.scss";
import Loader from "../../Components/Loader/Loader";
import WindowIcon from "../../Assets/Icons/WindowIcon";
import EmojiUserIcon from "../../Assets/Icons/EmojiIUserIcon";
import PathsColumn from "../DomainDetails/PathsColumn";

const { TabPane } = Tabs;

const GoogleAdsDetails = () => {
  const navigate = useNavigate();
  const { profileData } = useUserContext();
  const location = useLocation();
  const rowData = location.state?.data;
  const [birdViewData, setBirdViewData] = useState([]);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timeLineData, setTimeLineData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [timeLineLoading, setTimeLineLoading] = useState(false);
  const [birdViewLoading, setBirdViewLoading] = useState(false);

  const fetchTimeLineData = async () => {
    setTimeLineLoading(true);
    const payload = {
      companyDomain: rowData.companyDomain,
      projectName: profileData.paidSearchProjectName,
    };
    const { statusCode, data } = await postApi(GET_ALL_TIMELINE, payload);
    setTimeLineLoading(false);
    if (statusCode === 200) {
      setTimeLineData(data);
    } else {
      setIsLoading(false);
      setTimeLineData([]);
    }
  };

  const fetchBirdViewData = async () => {
    setBirdViewLoading(true);
    const payload = {
      companyDomain: rowData.companyDomain,
      projectName: profileData.paidSearchProjectName,
    };
    const { statusCode, data } = await postApi(GET_ALL_BIRDVIEW, payload);
    setBirdViewLoading(false);
    if (statusCode === 200) {
      const { filteredData } = filterData(data);
      setBirdViewData(filteredData);
      setColumns(generateColumns(filteredData));
    } else {
      setBirdViewData([]);
    }
  };

  useEffect(() => {
    fetchTimeLineData();
    fetchBirdViewData();
  }, [profileData, rowData]);

  const filterData = (data) => {
    const filteredData = [];
    const uniqueIdentifiers = new Set();

    for (const day of data) {
      const date = new Date(day.date).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
      const userActivities = {};

      for (const timeline of day.timeline) {
        const userActivity = timeline.userActivity;
        if (!Array.isArray(userActivity)) {
          console.warn("Unexpected userActivity format. Skipping...");
          continue;
        }
        for (const activity of userActivity) {
          if (activity.pageUrl === null) continue;
          const activities = activity;

          // Patch to use username, userId, or userEmail
          const identifier =
            timeline.userName ||
            timeline.userId ||
            timeline.userEmail ||
            "anonymous";

          uniqueIdentifiers.add(identifier);

          if (!userActivities[identifier]) {
            userActivities[identifier] = [];
          }
          userActivities[identifier].push(activities);
        }
      }

      const formattedActivities = { date };
      for (const [identifier, urls] of Object.entries(userActivities)) {
        formattedActivities[identifier] = urls;
      }
      filteredData.push(formattedActivities);
    }

    return {
      filteredData,
      uniqueIdentifiersCount: uniqueIdentifiers.size,
    };
  };

  const generateColumns = (data) => {
    const uniqueIdentifiers = new Set();
    data.forEach((day) => {
      Object.keys(day).forEach((key) => {
        if (key !== "date") {
          uniqueIdentifiers.add(key);
        }
      });
    });

    return [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        width: 150,
      },
      ...Array.from(uniqueIdentifiers).map((identifier) => ({
        title: identifier,
        dataIndex: identifier,
        key: identifier,
        render: (params) => <PathsColumn urls={params} />,
      })),
    ];
  };

  const showDrawer = (activity) => {
    setCurrentRecord(activity);
    setIsDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setIsDrawerVisible(false);
    setCurrentRecord(null);
  };

  const fieldMappings = [
    { label: "Company Domain", key: "companyDomain" },
    { label: "Company Name", key: "companyName" },
    { label: "Company Employee Range", key: "companyEmployeeRange" },
    { label: "Company Industry", key: "companyIndustry" },
    { label: "Company Annual Revenue Range", key: "companyAnnualRevenueRange" },
    { label: "Account First Channel", key: "userFirstChannel" },
    { label: "Account Latest Term", key: "userLatestSearchTerm" },
    {
      label: "Account Latest Referrer Domain",
      key: "userLatestReferrerDomain",
    },
    { label: "Account Latest Campaign", key: "userLatestCampaign" },
    { label: "Company Type", key: "companyType" },
    { label: "Company ID", key: "companyID" },
    { label: "Company Employee Count", key: "companyEmployeeCount" },
    { label: "Company State", key: "companyState" },
    { label: "Company Country", key: "companyCountryISOCode" },
    { label: "Company Legal Name", key: "companyLegalName" },
    { label: "Company Funding Raised", key: "companyFundingRaised" },
    { label: "Company Market Cap", key: "companyMarketCap" },
    { label: "Company SIC Code", key: "companySICCode" },
    { label: "Company Industry Group", key: "companyIndustryGroup" },
    { label: "Company Sub Industry", key: "companySubIndustry" },
    { label: "Company NAICS Code", key: "companyNAICSCode" },
    { label: "Company Sector", key: "companySector" },
    { label: "User Country", key: "userCountry" },
  ];

  const hanleAccountsPage = () => {
    navigate("/google-ads");
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="domain">
        <div className="domain_header">
          <h2>
            <span onClick={hanleAccountsPage}>Google Ads</span> /{" "}
            {rowData ? rowData.companyDomain : "-"}
          </h2>
        </div>
        <div className="domain_section">
          <div className="domain_section_left">
            <div className="domain_section_left_top">
              <img
                src={rowData.companyDomainUrl}
                onError={(e) =>
                  (e.target.src =
                    "https://upload.wikimedia.org/wikipedia/commons/c/ce/Example_image.png")
                }
                alt={"Logo"}
              />
              <a
                href={rowData ? `https://${rowData.companyDomain}` : "#"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {rowData ? rowData.companyDomain : "-"}
              </a>
            </div>
            <div className="domain_section_left_center">
              {fieldMappings.map(({ label, key }) => (
                <div className="domain_section_left_center_card" key={key}>
                  <p>{label}:</p>
                  <span>{rowData?.[key] || "-"}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="domain_section_right">
            <div className="content">
              <Tabs defaultActiveKey="1" style={{ marginTop: "20px" }}>
                <TabPane tab="Timeline" key="1">
                  {timeLineLoading ? (
                    <Loader />
                  ) : (
                    <div className="timeline_container">
                      {timeLineData.map((entry, index) => (
                        <div key={index}>
                          <div className="timeline_header">
                            <h3>
                              {new Date(entry.date).toLocaleDateString(
                                "en-US",
                                {
                                  weekday: "short",
                                  year: "numeric",
                                  month: "short",
                                  day: "2-digit",
                                }
                              )}
                            </h3>
                          </div>
                          {entry.timeline.map((activity, activityIndex) => (
                            <div
                              key={activityIndex}
                              className="timeline_details"
                            >
                              <p style={{ width: "10%" }}>
                                <strong>
                                  {new Date(
                                    activity.timestamp * 1000
                                  ).toLocaleTimeString("en-US", {
                                    hour: "numeric",
                                    minute: "2-digit",
                                    hour12: true,
                                  })}
                                </strong>
                              </p>

                              <div
                                className="timeline_details_view"
                                onClick={() => showDrawer(activity)}
                                style={{ width: "15%" }}
                              >
                                <WindowIcon />
                                <p>Page View</p>
                              </div>

                              <span style={{ width: "30%" }}>
                                Page URL:
                                {activity.pageUrl !== "N/A" ? (
                                  <p>{activity.pageUrl}</p>
                                ) : (
                                  "N/A"
                                )}
                              </span>

                              <div className="timeline_details_view">
                                <EmojiUserIcon />
                                <p>
                                  {activity.userName
                                    ? activity.userName
                                    : activity.userID
                                    ? activity.userID
                                    : activity.userEmail
                                    ? activity.userEmail
                                    : "Anonymous User"}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  )}
                </TabPane>

                <TabPane tab="Birdview" key="2">
                  <Table
                    columns={columns}
                    dataSource={birdViewData}
                    loading={birdViewLoading}
                    pagination={false}
                    size="small"
                    locale={{
                      emptyText: (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description="No Data"
                        />
                      ),
                    }}
                  />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        <Drawer
          title="More Details"
          placement="right"
          onClose={onCloseDrawer}
          open={isDrawerVisible}
          width={400}
        >
          <Tabs defaultActiveKey="1" style={{ marginTop: "20px" }}>
            <TabPane tab="User Properties" key="1">
              <div className="drawer">
                <div className="drawer_header">
                  <h2>{currentRecord?.userName}</h2>
                </div>
                {currentRecord && (
                  <div className="drawer_event">
                    <p>
                      <strong>Company Employee Range: </strong>
                      {currentRecord.companyEmployeeRange || "-"}
                    </p>
                    <p>
                      <strong>Company Industry: </strong>
                      {currentRecord.companyIndustry || "-"}
                    </p>
                    <p>
                      <strong>User Name: </strong>
                      {currentRecord.userName || "-"}
                    </p>
                    <p>
                      <strong>User Browser: </strong>
                      {currentRecord.userBrowser || "-"}
                    </p>
                    <p>
                      <strong>User ID: </strong>
                      {currentRecord.userID || "-"}
                    </p>
                    <p>
                      <strong>User Latest Page Domain: </strong>
                      {currentRecord.userLatestPageDomain || "-"}
                    </p>
                    <p>
                      <strong>User First Channel: </strong>
                      {currentRecord.userFirstChannel || "-"}
                    </p>
                    <p>
                      <strong>Company LinkedIn URL: </strong>
                      {currentRecord.companyLinkedInUrl || "-"}
                    </p>
                    <p>
                      <strong>User Latest Page URL: </strong>
                      {currentRecord.userLatestPageURL || "-"}
                    </p>
                    <p>
                      <strong>User First Page Raw URL: </strong>
                      {currentRecord.userFirstPageRawUrl || "-"}
                    </p>
                    <p>
                      <strong>User First Page Active Time: </strong>
                      {currentRecord.userFirstPageActiveTime
                        ? `${currentRecord.userFirstPageActiveTime} Seconds`
                        : "-"}
                    </p>
                    <p>
                      <strong>User Total Active Time: </strong>
                      {currentRecord.userTotalActiveTime
                        ? `${currentRecord.userTotalActiveTime} Seconds`
                        : "-"}
                    </p>
                  </div>
                )}
              </div>
            </TabPane>
            <TabPane tab="Event Properties" key="2">
              <div className="drawer">
                <div className="drawer_header">
                  <h2>
                    <WindowIcon />
                    Page View
                  </h2>
                </div>
                {currentRecord && (
                  <div className="drawer_event">
                    <p>
                      <strong>Page URL: </strong>
                      {currentRecord.pageURL || "-"}
                    </p>
                    <p>
                      <strong>Page Referrer URL: </strong>
                      {currentRecord.pageReferrerURL || "-"}
                    </p>
                    <p>
                      <strong>Page Active Time: </strong>
                      {currentRecord.pageActiveTime
                        ? `${currentRecord.pageActiveTime} Seconds`
                        : "-"}
                    </p>
                    <p>
                      <strong>Page Scroll Percent: </strong>
                      {currentRecord.pageScrollPercent || "-"}
                    </p>
                    <p>
                      <strong>Day Of Occurence: </strong>
                      {currentRecord.firstSeenDay || "-"}
                    </p>
                  </div>
                )}
              </div>
            </TabPane>
          </Tabs>
        </Drawer>
      </div>
    </>
  );
};

export default GoogleAdsDetails;
