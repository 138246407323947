import React from "react";

const EmojiUserIcon = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 96 96"
      className="className"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="96" height="96" rx="48" fill="#FA4300"></rect>
      <circle cx="31" cy="48" r="5" fill="#0E2647"></circle>
      <circle cx="65" cy="48" r="5" fill="#0E2647"></circle>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.245 59.8121C37.9011 58.2906 39.6663 57.5891 41.1877 58.2452C42.6811 58.8891 45.3149 59.7011 48.112 59.9347C50.9577 60.1723 53.508 59.7762 55.2851 58.5383C56.6446 57.5913 58.5144 57.9257 59.4614 59.2852C60.4085 60.6448 60.074 62.5146 58.7145 63.4616C55.2916 65.8459 51.0919 66.2044 47.6126 65.9139C44.0847 65.6192 40.8185 64.6201 38.8119 63.7548C37.2905 63.0987 36.5889 61.3335 37.245 59.8121Z"
        fill="#0E2647"
      ></path>
    </svg>
  );
};

export default EmojiUserIcon;
