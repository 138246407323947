import React from "react";

const DownTrend = ({ color = "#000" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8463 3.63931C15.0455 3.83054 15.0519 4.14706 14.8607 4.34627L11.5007 7.84627C11.4064 7.94448 11.2761 8 11.14 8C11.0039 8 10.8736 7.94448 10.7793 7.84627L8.74 5.72199L6.32335 8.23933L8.04513 9.89223L2.5 11.5L3.88019 5.8939L5.60197 7.5468L8.37931 4.65373C8.47359 4.55552 8.60385 4.5 8.74 4.5C8.87615 4.5 9.00641 4.55552 9.10069 4.65373L11.14 6.77801L14.1393 3.65373C14.3305 3.45453 14.6471 3.44807 14.8463 3.63931Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};

export default DownTrend;
