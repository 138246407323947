import React, { useState } from "react";
import "./ForgotPassword.scss";
import Logo from "../../Assets/Images/Login-Logo.png";
import { postApi } from "../../Utils/apiService";
import { FORGOT_PASSWORD } from "../../Utils/apiPath";
import { errorToast, successToast } from "../../Services/ToastHelper";
import Loader from "../../Components/Loader/Loader";
import FormInputs from "../../Components/FormInputs/FormInputs";
import Buttons from "../../Components/Buttons/Buttons";
import { LeftCircleSVG } from "../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const validateEmail = () => {
    if (!email) {
      setError("This field is required");
      return false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Enter a valid email");
      return false;
    }
    return true;
  };

  const handleSendLink = async () => {
    if (validateEmail()) {
      setIsLoading(true);
      const payload = { email };
      const { statusCode, message, error } = await postApi(
        FORGOT_PASSWORD,
        payload
      );
      if (statusCode === 200) {
        successToast(message);
        setIsLoading(false);
      } else {
        errorToast(error);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="forgot-password">
      {isLoading && <Loader />}
      <div className="forgot-password_cont">
        <div className="forgot-password_cont_sec">
          <div className="forgot-password_cont_sec_left">
            <div className="forgot-password_cont_sec_left_logo">
              <img src={Logo} alt="Company Logo" />
            </div>
            <div className="forgot-password_cont_sec_left_content">
              <div className="forgot-password_cont_sec_left_content_head">
                <h1>Forgot Your Password?</h1>
                <p>Enter your email address to receive a reset password link</p>
              </div>
              <div className="forgot-password_cont_sec_left_content_data">
                <div className="forgot-password_cont_sec_left_content_data_input">
                  <FormInputs
                    title={"Email"}
                    type={"text"}
                    placeholder={"Example@email.com"}
                    name="email"
                    value={email}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSendLink();
                      }
                    }}
                  />
                  {error && <span className="error">{error}</span>}
                </div>
              </div>

              <div className="forgot-password_cont_sec_left_content_forgot">
                <LeftCircleSVG onClick={() => navigate("/")} />
                <span onClick={() => navigate("/")}>Back to login</span>
              </div>

              <div className="forgot-password_cont_sec_left_content_button">
                <Buttons variant="primary" onClick={handleSendLink}>
                  Send Reset Link
                </Buttons>
              </div>
              <div className="forgot-password_cont_sec_left_content_copy">
                <p>© 2024 ALL RIGHTS RESERVED - LakeB2B</p>
              </div>
            </div>
          </div>
          <div className="forgot-password_cont_sec_right"></div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
