// User Auth
export const USER_LOGIN = "Account/Login";
export const FORGOT_PASSWORD = "Account/ForgotPassword";
export const RESET_PASSWORD = "Account/ResetPassword";
export const GET_ALL_WEBHOOKEVENTS = "Account/GetAllWebhookEventsPagged";
export const GET_BIRDVIEW_DATA = "Account/GetAllWebhookEventsByDomainName";

//Campaign
export const GET_ALL_CAMPAIGNS = "UserAdmin/GetAllCampaignByUserId";
export const GET_DEVICE_STATS = "UserAdmin/GetDeviceStatsByCampaignId";
export const GET_BROWSER_STATS = "UserAdmin/GetBrowserStatsByCampaignId";
export const GET_DEVICE_STATS_MONTHLY =
  "UserAdmin/GetDeviceStatsSummaryByMonthByCampaignId";
export const GET_CAMPAIGN_STATS = "UserAdmin/GetCampaignStats";
export const GET_USER_PROFILE_DETAILS = "UserAdmin/GetUserProfile";
export const UPDATE_USER_PROFILE = "Account/UpdateUserProfile";
export const PASSWORD_CHANGE_API = "Account/ChangePassword";
export const GET_ALL_TIMELINE = "Account/GetTimeLineViewByDomainName";
export const GET_ALL_BIRDVIEW = "Account/GetBirdViewByDomainName";

//ICP
export const ADD_OR_EDIT_ICP = "UserAdmin/AddOrEditIcp";
export const GET_ALL_ICP_LIST = "UserAdmin/GetSetIcpByUserId";
export const DELETE_ICP = "UserAdmin/DeleteIcp";
export const ICP_DROPDOWN = "UserAdmin/GetSetIcpByUserIdForDropDown";
