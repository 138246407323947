import React, { useState, useEffect, useCallback } from "react";
import Buttons from "../../Components/Buttons/Buttons";
import "./SetIcp.scss";
import { useNavigate } from "react-router-dom";
import { DeleteTwoTone, EditTwoTone, SearchOutlined } from "@ant-design/icons";
import { Input, message, Popconfirm, Table, Tooltip } from "antd";
import Loader from "../../Components/Loader/Loader";
import useTableHeight from "../../Services/TableHeight";
import { deleteApi, postApi } from "../../Utils/apiService";
import { DELETE_ICP, GET_ALL_ICP_LIST } from "../../Utils/apiPath";
import { debounce } from "lodash";
import { errorToast, successToast } from "../../Services/ToastHelper";
import { formatUTCDate } from "../../Services/HelperFunctions";

const SetIcp = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [icpData, setIcpData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const handleIcpNav = () => {
    navigate("/icp-details");
  };

  const fetchIcpDetails = async () => {
    setIsLoading(true);
    const payload = {
      pageIndex: pageIndex - 1,
      pageSize,
      searchString,
    };
    try {
      const { statusCode, data, totalCount } = await postApi(
        GET_ALL_ICP_LIST,
        payload
      );
      if (statusCode === 200) {
        setIcpData(data);
        setTotalCount(totalCount);
      } else {
        setIcpData([]);
        errorToast(message);
      }
    } catch (error) {
      message.error("An error occurred while fetching ICP data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchIcpDetails();
  }, [pageIndex, pageSize]);

  const debouncedFetchIcpDetails = useCallback(debounce(fetchIcpDetails, 500), [
    pageIndex,
    pageSize,
  ]);

  const handleSearch = (e) => {
    setSearchString(e.target.value);
    debouncedFetchIcpDetails();
  };

  const columns = [
    {
      title: "SL No",
      dataIndex: "serialNumber",
      render: (text, record, index) => (pageIndex - 1) * pageSize + index + 1,
      width: 100,
    },
    {
      title: "ICP Name",
      dataIndex: "name",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      render: (text) => formatUTCDate(text),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: 150,
      render: (_, record) => (
        <div style={{ display: "flex", gap: "20px" }}>
          <Tooltip title="Edit ICP">
            <span onClick={() => handleEdit(record)}>
              <EditTwoTone style={{ cursor: "pointer", fontSize: "20px" }} />
            </span>
          </Tooltip>
          <Tooltip title="Delete ICP">
            <Popconfirm
              title="Are you sure to delete this ICP?"
              onConfirm={() => handleDelete(record)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <span>
                <DeleteTwoTone
                  twoToneColor="red"
                  style={{ cursor: "pointer", fontSize: "20px", color: "red" }}
                />
              </span>
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleEdit = (record) => {
    navigate("/icp-details", { state: { id: record.id, data: record } });
  };

  const handleDelete = async (record) => {
    setIsLoading(true);
    try {
      const { statusCode, message } = await deleteApi(DELETE_ICP, {
        params: { icpId: record.id },
      });
      if (statusCode === 200) {
        successToast(message);
        fetchIcpDetails();
      } else {
        errorToast(message);
      }
    } catch (error) {
      message.error("An error occurred while deleting ICP");
    } finally {
      setIsLoading(false);
    }
  };

  const tableHeight = useTableHeight();

  const getPageSizeOptions = () => {
    return totalCount <= 50 ? ["10", "20", "50"] : ["10", "20", "50", "100"];
  };

  return (
    <div className="icp">
      <div className="icp_cont">
        <div className="icp_cont_btn">
          <h2>ICP List</h2>
          <div className="icp_cont_btn_right">
            <Input
              placeholder="Search ICP"
              size="large"
              prefix={<SearchOutlined />}
              value={searchString}
              onChange={handleSearch}
              onPressEnter={fetchIcpDetails}
              style={{ width: "250px" }}
            />
            <Buttons variant="primary" onClick={handleIcpNav}>
              Create ICP
            </Buttons>
          </div>
        </div>
        <div className="icp_cont_table">
          {isLoading ? (
            <Loader />
          ) : (
            <Table
              size="small"
              bordered
              columns={columns}
              dataSource={icpData}
              pagination={{
                current: pageIndex,
                pageSize,
                total: totalCount,
                showSizeChanger: true,
                pageSizeOptions: getPageSizeOptions(),
                onChange: (page, size) => {
                  setPageIndex(page);
                  setPageSize(size);
                },
              }}
              scroll={{
                y: tableHeight,
              }}
              rowKey="companyID"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SetIcp;
