import React, { useState } from "react";
import { Popover } from "antd";
import PopupData from "./PopupData";

const PathsColumn = ({ urls }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  if (!urls || urls.length === 0) return <span>-</span>;

  const showMoreCount = urls.length - 1;

  const urlStyle = {
    display: "inline-block",
    padding: "4px 8px",
    backgroundColor: "#f0f0f0",
    borderRadius: "4px",
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Popover
          content={<PopupData urls={urls[0]} />}
          trigger="hover"
          placement="topRight"
        >
          {urls[0]?.pageUrl && (
            <span style={{ color: "black", ...urlStyle }}>{urls[0].pageUrl}</span>
          )}
        </Popover>
        {!isExpanded && showMoreCount > 0 && (
          <a onClick={toggleExpand} style={{ color: "blue", ...urlStyle }}>
            +{showMoreCount}
          </a>
        )}
      </div>
      {isExpanded && showMoreCount > 0 && (
        <div style={{ marginTop: "5px" }}>
          {urls.slice(1).map((url, index) => (
            <Popover
              key={index}
              content={<PopupData urls={url} />}
              trigger="hover"
              placement="topRight"
            >
              {urls[0]?.pageUrl && (
                <span style={{ color: "black", ...urlStyle }}>{urls[0].pageUrl}</span>
              )}
              <br />
            </Popover>
          ))}
          <a onClick={toggleExpand} style={{ color: "blue", marginTop:"5px", ...urlStyle }}>
            Show Less
          </a>
        </div>
      )}
    </div>
  );
};

export default PathsColumn;
