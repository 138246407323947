
export const SearchIcon = ({ className }) => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 15"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7871 13.6626L10.3359 9.94962C11.2233 8.85844 11.7095 7.48552 11.7095 6.05624C11.7095 2.71688 9.08299 0 5.85474 0C2.62649 0 0 2.71688 0 6.05624C0 9.3956 2.62649 12.1125 5.85474 12.1125C7.06667 12.1125 8.22158 11.7344 9.20899 11.0166L12.6864 14.7578C12.8318 14.9139 13.0273 15 13.2368 15C13.4351 15 13.6232 14.9218 13.766 14.7796C14.0694 14.4776 14.0791 13.9768 13.7871 13.6626ZM5.85474 1.57989C8.24092 1.57989 10.1821 3.58793 10.1821 6.05624C10.1821 8.52456 8.24092 10.5326 5.85474 10.5326C3.46855 10.5326 1.52732 8.52456 1.52732 6.05624C1.52732 3.58793 3.46855 1.57989 5.85474 1.57989Z"
        fill="#707070"
      />
    </svg>
  );
};

export const PasswordShow = ({ className }) => {
  return (
    <svg
      width="18px"
      height="16px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 12C1 12 5 20 12 20C19 20 23 12 23 12"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="12"
        cy="12"
        r="3"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PasswordHide = ({ className }) => {
  return (
    <svg
      width="18px"
      height="16px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.83 9.17999C14.2706 8.61995 13.5576 8.23846 12.7813 8.08386C12.0049 7.92926 11.2002 8.00851 10.4689 8.31152C9.73758 8.61453 9.11264 9.12769 8.67316 9.78607C8.23367 10.4444 7.99938 11.2184 8 12.01C7.99916 13.0663 8.41619 14.08 9.16004 14.83"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16.01C13.0609 16.01 14.0783 15.5886 14.8284 14.8384C15.5786 14.0883 16 13.0709 16 12.01"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.61 6.39004L6.38 17.62C4.6208 15.9966 3.14099 14.0944 2 11.99C6.71 3.76002 12.44 1.89004 17.61 6.39004Z"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9994 3L17.6094 6.39"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.38 17.62L3 21"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5695 8.42999C20.4801 9.55186 21.2931 10.7496 21.9995 12.01C17.9995 19.01 13.2695 21.4 8.76953 19.23"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const GoogleIcon = ({ className }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_121_2154)">
        <path
          d="M27.727 14.3225C27.727 13.3708 27.6499 12.414 27.4853 11.4777H14.28V16.8689H21.842C21.5283 18.6076 20.52 20.1457 19.0436 21.1231V24.6212H23.5551C26.2044 22.1828 27.727 18.5819 27.727 14.3225Z"
          fill="#4285F4"
        />
        <path
          d="M14.28 28.0009C18.0559 28.0009 21.2402 26.7612 23.5602 24.6212L19.0487 21.1231C17.7935 21.9771 16.1731 22.4606 14.2852 22.4606C10.6328 22.4606 7.53596 19.9965 6.42481 16.6837H1.76929V20.2898C4.14592 25.0173 8.98663 28.0009 14.28 28.0009Z"
          fill="#34A853"
        />
        <path
          d="M6.41966 16.6837C5.83322 14.9449 5.83322 13.0621 6.41966 11.3234V7.71729H1.76928C-0.216388 11.6732 -0.216388 16.3339 1.76928 20.2898L6.41966 16.6837Z"
          fill="#FBBC04"
        />
        <path
          d="M14.28 5.54127C16.276 5.51041 18.2051 6.26146 19.6506 7.64012L23.6477 3.64305C21.1167 1.26642 17.7575 -0.0402103 14.28 0.000943444C8.98663 0.000943444 4.14592 2.98459 1.76929 7.71728L6.41966 11.3234C7.52567 8.00536 10.6276 5.54127 14.28 5.54127Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_121_2154">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};


export const LeftCircleSVG = ({ fill = "#fff", className, onClick  }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    className="left-circle-icon"
    width="35"
    height="35"
    onClick ={onClick }
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 3.75a8.25 8.25 0 100 16.5 8.25 8.25 0 000-16.5z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.75 9l-3 3 3 3m-3-3h7.5"
    />
  </svg>
);

