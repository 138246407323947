import React, { useEffect, useState } from "react";
import "./Login.scss";
import Logo from "../../Assets/Images/Login-Logo.png";
import { useNavigate } from "react-router-dom";
import FormInputs from "../../Components/FormInputs/FormInputs";
import Buttons from "../../Components/Buttons/Buttons";
import { postApi } from "../../Utils/apiService";
import { USER_LOGIN } from "../../Utils/apiPath";
import { errorToast, successToast } from "../../Services/ToastHelper";
import Loader from "../../Components/Loader/Loader";

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, [])
  
  const handleChange = (e) => {
    const { name, value } = e.target;

    setLogin({
      ...login,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateFields = () => {
    let errObj = { ...initialValues };

    if (!login.email) {
      errObj.email = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(login.email)) {
      errObj.email = "Enter Valid Email Id";
    } else {
      errObj.email = "";
    }

    if (!login.password) {
      errObj.password = "This field is required";
    } else if (login.password.length < 8) {
      errObj.password = "Password must be at least 8 characters";
    } else {
      errObj.password = "";
    }

    setErrors((prev) => ({ ...prev, ...errObj }));
    const data = Object.values(errObj).every((x) => x === "" || x === null);
    return data;
  };

  const handleLogin = async () => {
    if (validateFields()) {
      setIsLoading(true);
      const payload = {
        email: login.email,
        password: login.password,
      };
      const { statusCode, data, message, error } = await postApi(USER_LOGIN, payload);
      if (statusCode === 200) {
        localStorage.setItem("accessToken", data.accessToken);
        setIsLoading(false);
        successToast(message);
        navigate("/dashboard");
      } else{
        setIsLoading(false);
        errorToast(error);
      }
    }
  };

  return (
    <div className="login">
      {isLoading && <Loader />}
      <div className="login_cont">
        <div className="login_cont_sec">
          <div className="login_cont_sec_left">
            <div className="login_cont_sec_left_logo">
              <img src={Logo} alt="Company Logo" />
            </div>
            <div className="login_cont_sec_left_content">
              <div className="login_cont_sec_left_content_head">
                <h1>Welcome Back to ICP Tech</h1>
                <p>
                  Focused Engagement with Your Ideal Customer Profile and Leads
                  visiting your website
                </p>
              </div>
              <div className="login_cont_sec_left_content_data">
                <div className="login_cont_sec_left_content_data_input">
                  <FormInputs
                    title={"Email"}
                    type={"text"}
                    placeholder={"Example@email.com"}
                    name="email"
                    value={login.email}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleLogin();
                      }
                    }}
                  />
                  {errors.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </div>
                <div className="login_cont_sec_left_content_data_input">
                  <FormInputs
                    title={"Password"}
                    type={"password"}
                    placeholder={"at least 8 characters"}
                    name="password"
                    value={login.password}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleLogin();
                      }
                    }}
                  />
                  {errors.password && (
                    <span className="error">{errors.password}</span>
                  )}
                </div>
              </div>
              <div className="login_cont_sec_left_content_forgot" onClick={()=> navigate("/forgot-password")}>
                <span>Forgot Password?</span>
              </div>
              <div className="login_cont_sec_left_content_button">
                <Buttons variant="primary" onClick={handleLogin}>
                  Sign In
                </Buttons>
              </div>
              {/* <div className="login_cont_sec_left_content_lines">
                <div className="line"></div>
                <p>Or</p>
                <div className="line"></div>
              </div>
              <div className="login_cont_sec_left_content_google">
                <Buttons variant="google">
                  <GoogleIcon />
                  Sign with Google
                </Buttons>
              </div>
              <div className="login_cont_sec_left_content_last">
                <p>
                  Don't you have an account? <span>Sign up</span>
                </p>
              </div> */}
              <div className="login_cont_sec_left_content_copy">
                <p>© 2024 ALL RIGHTS RESERVED - LakeB2B</p>
              </div>
            </div>
          </div>
          <div className="login_cont_sec_right"></div>
        </div>
      </div>
    </div>
  );
};

export default Login;
