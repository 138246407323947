import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import Highmaps from "highcharts/modules/map";
import HighchartsReact from "highcharts-react-official";

Highmaps(Highcharts);

const MapChart = () => {
  const [chartOptions, setChartOptions] = useState({});
  const [mapData, setMapData] = useState({});
  const [mapName, setMapName] = useState("World, medium resolution");
  const [mapKey, setMapKey] = useState("custom/world");
  const [dataLabels, setDataLabels] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 
  const chartRef = useRef(null);

  const baseMapPath = "https://code.highcharts.com/mapdata/";
  const allMaps = {};

  useEffect(() => {
    const fetchInitialMapData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${baseMapPath}${mapKey}.topo.json`);
        const data = await response.json();
        setMapData(data);
      } catch (e) {
        console.error("Error fetching map data:", e);
      } finally {
        setIsLoading(false); 
      }
    };

    fetchInitialMapData();
  }, [mapKey]);

  useEffect(() => {
    if (mapData && Object.keys(mapData).length > 0) {
      updateChart(mapName, mapData);
    }
  }, [mapName, mapData]);

  const updateChart = (mapName, mapData) => {
    const data = mapData.objects.default.geometries.map((g, value) => ({
      key: g.properties["hc-key"],
      drilldown: g.properties["hc-key"],
      value,
    }));

    setChartOptions({
      chart: {
        backgroundColor: "#F7F9FB",
        events: {
          drilldown: handleDrilldown,
          drillup: handleDrillup,
          afterDrilldown: function () {
            this.credits.update();
          },
        },
        height: 260, 
        mapView: {
          projection: {
            name: 'EqualEarth' 
          }
        },
      },
      colorAxis: {
        max: 100,
        min: 20,
        tickInterval: 20,
        stops: [
          [0, "#F5F5FF"],
          [0.5, "#A080FF"],
          [1, "#4B0082"],
        ],
      },
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
      },
      plotOptions: {
        map: {
          dataLabels: {
            enabled: dataLabels,
          },
        },
      },
      series: [
        {
          data,
          mapData,
          joinBy: ["hc-key", "key"],
          name: mapName,
          dataLabels: {
            enabled: dataLabels,
          },
          custom: {
            mapName,
            mapKey,
          },
        },
      ],
      title: {
        text: "Interest by country",
        align: "left",
        style: {
          fontSize: "14px",
        },
      },
      credits: {
        enabled: false,
      },
      accessibility: {
        enabled: false,
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 550,  
            },
            chartOptions: {
              colorAxis: {
                layout: "horizontal",
              },
              legend: {
                align: "center",
                layout: "horizontal",
                verticalAlign: "bottom",
              },
              chart: {
                height: 300, 
              },
              mapNavigation: {
                buttonOptions: {
                  verticalAlign: "bottom",
                },
              },
            },
          },
        ],
      },
    });
    
  };

  const handleDrilldown = async (e) => {
    const map =
      Object.entries(allMaps).find(([name]) => name === e.point.name) ||
      Object.entries(allMaps).find(
        ([name]) => name.indexOf(e.point.name) === 0
      );

    if (map && !e.seriesOptions) {
      const [mapName, mapPath] = map;
      const mapKey = mapPath.slice(0, -3);
      try {
        const response = await fetch(`${baseMapPath}${mapKey}.topo.json`);
        const data = await response.json();
        updateChart(mapName, data);
        setMapName(mapName);
        setMapKey(mapKey);
      } catch (e) {
        console.error("Error fetching drilldown map data:", e);
      }
    }
  };

  const handleDrillup = (e) => {
    const { mapName, mapKey } = e.seriesOptions.custom;
    if (mapName && mapKey) {
      updateChart(mapName, mapData);
      setMapName(mapName);
      setMapKey(mapKey);
    }
    chartRef.current?.chart.credits.update();
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.reflow(); 
    }
  }, [chartOptions, mapData]);

  return (
    <div>
      {isLoading ? (
        <div>Loading Map...</div>
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={"mapChart"}
          ref={chartRef}
          options={chartOptions}
        />
      )}
    </div>
  );
};

export default MapChart;
