import React from "react";
import "./Buttons.scss";

const Buttons = ({ children, type, onClick, className, variant, style, disabled }) => {
  return (
    <div>
      <button
        type={type}
        onClick={onClick}
        className={`custom-button ${className} ${variant}`}
        style={style}
        disabled = {disabled}
      >
        {children}
      </button>
    </div>
  );
};

export default Buttons;
