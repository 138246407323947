import { createContext, useContext, useEffect, useState } from "react";
import { GET_USER_PROFILE_DETAILS } from "../Utils/apiPath";
import { getApi } from "../Utils/apiService";

const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [profileData, setProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getUserProfileData = async () => {
    try {
      setIsLoading(true);
      const { statusCode, data } = await getApi(GET_USER_PROFILE_DETAILS);

      if (statusCode === 200) {
        setProfileData(data);
      } else {
        console.error("Failed to fetch user profile data.");
        setProfileData({});
      }
    } catch (error) {
      console.error("An error occurred while fetching profile data:", error);
      setProfileData({});
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserProfileData();
  }, []);

  return (
    <UserContext.Provider value={{ isLoading, profileData, setProfileData }}>
      {children}
    </UserContext.Provider>
  );
};
