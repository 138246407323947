import React, { useEffect } from "react";
import "./IcpDetails.scss";
import { useState } from "react";
import { Input, Select } from "antd";
import {
  industry_data,
  department_data,
  level_data,
  employee_data,
  revenue_data,
  location_data,
} from "../../../Services/icp_attributes_maste";
import Buttons from "../../../Components/Buttons/Buttons";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../Components/Loader/Loader";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { postApi } from "../../../Utils/apiService";
import { ADD_OR_EDIT_ICP } from "../../../Utils/apiPath";
import { errorToast, successToast } from "../../../Services/ToastHelper";

const IcpDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);
  const [recordId, setRecordId] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [selectedRevenue, setSelectedRevenue] = useState([]);

  const [errors, setErrors] = useState({
    name: "",
    levels: "",
    location: "",
    department: "",
  });

  useEffect(() => {
    if (location?.state && location?.state?.id) {
      setIsEditMode(true);
      setRecordId(location.state.id);
      const {
        name,
        location: recordLocation,
        employees,
        department,
        level,
        industry,
        revenue,
      } = location.state.data;
      setName(name);
      setSelectedLocations(recordLocation);
      setSelectedEmployees(employees);
      setSelectedDepartments(department);
      setSelectedIndustries(industry);
      setSelectedLevels(level);
      setSelectedRevenue(revenue);
    }
  }, [location?.state]);

  const industryOptions = industry_data.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const departmentOptions = department_data.map((item) => ({
    value: item.name,
    label: item.name,
  }));
  const levelsOptions = level_data.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const employeesOptions = employee_data.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const revenueOptions = revenue_data.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const locationOptions = location_data.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const handleBack = () => {
    navigate("/icp");
  };

  const handleClear = () => {
    if (isEditMode) {
      navigate(-1);
    } else {
      setName("");
      setSelectedLocations([]);
      setSelectedEmployees([]);
      setSelectedIndustries([]);
      setSelectedLevels([]);
      setSelectedDepartments([]);
      setSelectedRevenue([]);
    }
  };

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const validateFields = () => {
    let errObj = { ...errors };

    if (!name) {
      errObj.name = "Name is required";
    } else {
      errObj.name = "";
    }

    if (selectedLevels.length === 0) {
      errObj.levels = "Please select at least one level";
    } else {
      errObj.levels = "";
    }

    if (selectedDepartments.length === 0) {
      errObj.department = "Please select at least one department";
    } else {
      errObj.department = "";
    }

    if (selectedLocations.length === 0) {
      errObj.location = "Please select at least one location";
    } else {
      errObj.location = "";
    }

    setErrors((prev) => ({ ...prev, ...errObj }));
    return Object.values(errObj).every((x) => x === "");
  };

  const handleSave = async () => {
    if (validateFields()) {
      setIsLoading(true);

      const payload = {
        name,
        department: selectedDepartments,
        industry: selectedIndustries,
        level: selectedLevels,
        location: selectedLocations,
        revenue: selectedRevenue,
        employees: selectedEmployees,
        id: recordId || 0,
      };

      const { statusCode, message: apiMessage } = await postApi(
        ADD_OR_EDIT_ICP,
        payload
      );
      if (statusCode === 200) {
        successToast(apiMessage);
        handleClear();
        navigate("/icp");
      } else {
        errorToast(apiMessage);
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="icp">
      {isloading && <Loader />}
      <div className="icp_heading">
        <IoArrowBackCircleSharp
          onClick={handleBack}
          style={{ fontSize: "32px", cursor: "pointer" }}
        />
        <h1>{isEditMode ? "Edit ICP" : "Create ICP"}</h1>
      </div>
      <div className="icp_top">
        <div className="icp_top_name">
          <label className="labels">Enter ICP Name</label>
          <Input
            placeholder="Enter ICP Name"
            size="large"
            style={{ width: "100%" }}
            className="selects"
            value={name}
            onChange={handleChange}
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </div>
      </div>
      <div className="icp_cont">
        <div className="icp_cont_form">
          <div className="icp_cont_form_filds">
            <label className="labels">Level</label>
            <Select
              className="selects"
              mode="multiple"
              placeholder="Select Level"
              value={selectedLevels}
              onChange={setSelectedLevels}
              options={levelsOptions}
              style={{ width: "100%" }}
              size="large"
              allowClear
            />
            {errors.levels && <span className="error">{errors.levels}</span>}
          </div>
          <div className="icp_cont_form_filds">
            <label className="labels">Department</label>
            <Select
              className="selects"
              mode="multiple"
              placeholder="Select Department"
              value={selectedDepartments}
              onChange={setSelectedDepartments}
              options={departmentOptions}
              style={{ width: "100%" }}
              size="large"
              allowClear
            />
            {errors.department && (
              <span className="error">{errors.department}</span>
            )}
          </div>
          <div className="icp_cont_form_filds">
            <label className="labels">Industry</label>
            <Select
              className="selects"
              mode="multiple"
              placeholder="Select industries"
              value={selectedIndustries}
              onChange={setSelectedIndustries}
              options={industryOptions}
              style={{ width: "100%" }}
              size="large"
              allowClear
            />
          </div>
          <div className="icp_cont_form_filds">
            <label className="labels">Location</label>
            <Select
              className="selects"
              mode="multiple"
              placeholder="Select Location"
              value={selectedLocations}
              onChange={setSelectedLocations}
              options={locationOptions}
              style={{ width: "100%" }}
              size="large"
              allowClear
            />
            {errors.location && (
              <span className="error">{errors.location}</span>
            )}
          </div>
          <div className="icp_cont_form_filds">
            <label className="labels">Employees</label>
            <Select
              className="selects"
              mode="multiple"
              placeholder="Select Employees"
              value={selectedEmployees}
              onChange={setSelectedEmployees}
              options={employeesOptions}
              style={{ width: "100%" }}
              size="large"
              allowClear
            />
          </div>
          <div className="icp_cont_form_filds">
            <label className="labels">Revenue</label>
            <Select
              className="selects"
              mode="multiple"
              placeholder="Select Revenue"
              value={selectedRevenue}
              onChange={setSelectedRevenue}
              options={revenueOptions}
              style={{ width: "100%" }}
              size="large"
              allowClear
            />
          </div>
        </div>
        <div className="icp_btns">
          <Buttons variant="secondary" onClick={handleClear}>
            {isEditMode ? "Back" : "Clear"}
          </Buttons>
          <Buttons variant="primary" onClick={handleSave}>
            {isEditMode ? "Update" : "Save"}
          </Buttons>
        </div>
      </div>
    </div>
  );
};

export default IcpDetails;
